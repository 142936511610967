.ant-tabs {
  color: $color-dark;
  font-size: 1rem;
  font-weight: 500;
}

.ant-tabs-tab {
  font-size: 1rem;
  padding: 0 5px 15px 2px;
  line-height: 1.25em;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $color-blue-main;
}

.ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav, .ant-tabs-top > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav {
  margin-bottom: 2.5rem;
}

.ant-tabs-tab:hover {
  color: $color-blue-main;
}

.ant-tabs-ink-bar {
  background: $color-blue-main;
}

.ant-tabs-bottom > .ant-tabs-nav:before, .ant-tabs-bottom > div > .ant-tabs-nav:before, .ant-tabs-top > .ant-tabs-nav:before, .ant-tabs-top > div > .ant-tabs-nav:before {
  border-color: $color-grey;
}
