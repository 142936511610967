@import "src/styles";

.app {
  min-height: 100vh;
  background: $color-grey-light;

  .ant-spin-lg .ant-spin-dot.loading-indicator {
    font-size: 80px;
    color: $color-blue-main;
  }
}
