
.bg-primary {
  background: $color-blue-main !important;
}

.bg-secondary {
  background: $color-dark !important;
}

.bg-blue {
  background: $color-blue-dark !important;
}

.bg-gray {
  background: $color-text !important;
}

.bg-green {
  background: $color-light-green-1 !important;
}

.bg-black {
  background: $color-black !important;
}

.text-white {
  color: white !important;
}

.text-blue {
  color: $color-blue-main !important;
}

.text-red {
  color: $color-red !important;
}

.text-green {
  color: $color-light-green-1 !important;
}

.text-gray {
  color: $color-text !important;
}

.text-gray-dark {
  color: $color-text-primary !important;
}

.text-yellow {
  color: $color-light-yellow !important;
}
