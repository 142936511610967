.ant-rate {
  font-size: 0.875rem;

  .ant-rate-star {
    margin-right: 0.25rem;
    color: $color-yellow-2;

    svg {
      width: 1.125rem;
      height: 1.0625rem;
    }
  }
}
