.ant-switch {
  background: $color-grey;
  min-width: 36px;
  height: 16px;
  line-height: 16px;
}

.ant-switch-checked {
  background: $color-blue-main;
}

.ant-switch-handle {
  width: 20px;
  height: 20px;
  top: -2px;
  left: 0;

  &::before {
    border-radius: 50%;
    border: 1px solid $color-grey;
  }
}
