$color-white: #fff;
$color-black: #000;

$font-primary: 'Helvetica';

$color-dark: #1d2b4c;
$color-dark-1: #111827;
$color-blue-main: #108af9;
$color-blue-dark: #045fdb;
$color-blue-light: #90caf9;
$color-blue-ultra-light: #e7f1ff;
$color-blue-ultra-light-2: #edf1fb;
$color-blue-ultra-light-3: #e9f4fe;
$color-yellow: #f2c94c;
$color-yellow-1: #fbbf24;
$color-yellow-2: #fbbc04;
$color-yellow-ultra-light: #fef3c7;
$color-yellow-dark: #92400e;
$color-purple: #bb6bd9;
$color-grey: #bfc5d5;
$color-grey-light-1: #f2f5f8;
$color-grey-light-2: #e5e7eb;
$color-grey-light-3: #f9fafb;
$color-grey-light-4: #e0e0e0;
$color-grey-light: #f1f2f4;
$color-green: #6fcf97;
$color-green-dark: #133b3a;
$color-green-light: #4ea22f;
$color-green-ultra-light: #d1fae5;
$color-green-1: #34d399;
$color-green-2: #065f46;
$color-red: #b91c1c;
$color-red-dark: #991b1b;
$color-red-light: #eb5757;
$color-red-light-1: #f87171;
$color-red-ultra-light: #fee2e2;

$color-text: #7d7d7d;
$color-text-primary: #3d454a;
$color-dark-gray: #7396b0;
$color-gray: #aaa;
$color-light-gray: #e0e0e0;
$color-light-gray-1: #f4f4f7;
$color-light-gray-2: #dcdcdc;
$color-light-gray-3: #ebeced;
$color-light-green: #1bd64d;
$color-light-green-1: #4dd09a;
$color-light-yellow: #f5a623;
$color-light-yellow-1: #fff0d6;
$color-light-blue: #e9f3ff;
$color-light-blue-1: #f6faff;
$color-light-red-1: #ffe8e8;
$color-light-red: #ff6c6c;

$gradient-gray: linear-gradient(to right, #f4f4f7, #fff);

//$color-common: #3D454A;
//$color-comment1: #A1A1A1;
//$color-comment2: #707070;
//$color-header-title: #3C586C;
//$color-title1: #6E7D88;
//$color-title2: #292929;
//$color-title-background: #7396B010;

//$color-dark-green: #19BA59;
//$color-light-red: #FF1A1A;
//$color-dark-red: #F53D3E;
//$color-light-blue: #E9F3FF;
//$color-light-blue1: #D4E7FE;

$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 375px;
