.custom-tooltip {
  z-index: 1;

  .ant-tooltip-inner {
    background: $color-blue-main;
    border-radius: 4px;
  }

  .ant-tooltip-arrow-content {
    background-color: $color-blue-main;
  }
}
