.primary-btn {
  background: $color-dark !important;
  color: $color-white !important;
  text-transform: none !important;
  font-size: 0.8rem !important;
  padding: 0.75rem !important;
  outline: none !important;
  border: 1px solid $color-dark !important;

  .btn-icon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    background: #e6e6e6 !important;
    color: #ccc !important;
    opacity: 1;
    border-color: #e6e6e6 !important;
  }
}

.outlined-btn {
  background: $color-white !important;
  color: $color-dark !important;
  text-transform: none !important;
  font-size: 0.8rem !important;
  padding: 0.75rem !important;
  border: 1px solid $color-dark !important;

  .btn-icon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }

  &:disabled {
    background: #e6e6e6 !important;
    color: #ccc !important;
    border-color: #e6e6e6 !important;
    opacity: 1;
  }

  &:hover {
    opacity: 0.8;
  }
}

.box-shadow-btn {
  background: $color-white !important;
  color: $color-dark !important;
  text-transform: none !important;
  font-size: 0.8rem !important;
  width: 14rem;
  padding: 0.75rem !important;
  box-shadow: 0 0 10px 0 #0000002e;

  &:hover {
    opacity: 0.8;
  }

  .btn-icon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }
}

.circle-btn {
  background: $color-blue-main !important;
  color: $color-white !important;
  height: 2rem !important;
  width: 2rem !important;
  border-radius: 50% !important;
  text-transform: none !important;
  font-size: 0.8rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  outline: none !important;

  .btn-icon {
    line-height: 0;
    font-size: 1rem;
  }

  &:hover {
    opacity: 0.8;
  }
}

.dialog {
  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    padding: 0;
  }

  .dialog-header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $color-light-gray-2;
    position: relative;

    .title {
      font-size: 1.125rem;
      margin: 0;
    }

    .close-btn {
      right: 1.5rem;
      position: absolute;
      display: flex;
      padding: 0.125rem;

      svg {
        font-size: 1.2rem;
      }

      &:hover {
        color: $color-white;
        cursor: pointer;
      }
    }
  }

  .dialog-body {
    padding: 1.5rem 1.5rem;
  }
}

.pagination {
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.ant-divider {
  border-color: $color-grey;
}
