@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica.eot');
  src: url('/fonts/Helvetica.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Helvetica.woff2') format('woff2'),
  url('/fonts/Helvetica.woff') format('woff'),
  url('/fonts/Helvetica.ttf') format('truetype'),
  url('/fonts/Helvetica.svg#/fonts/Helvetica') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica-BoldOblique.eot');
  src: url('/fonts/Helvetica-BoldOblique.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Helvetica-BoldOblique.woff2') format('woff2'),
  url('/fonts/Helvetica-BoldOblique.woff') format('woff'),
  url('/fonts/Helvetica-BoldOblique.ttf') format('truetype'),
  url('/fonts/Helvetica-BoldOblique.svg#/fonts/Helvetica-BoldOblique') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica-LightOblique.eot');
  src: url('/fonts/Helvetica-LightOblique.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Helvetica-LightOblique.woff2') format('woff2'),
  url('/fonts/Helvetica-LightOblique.woff') format('woff'),
  url('/fonts/Helvetica-LightOblique.ttf') format('truetype'),
  url('/fonts/Helvetica-LightOblique.svg#/fonts/Helvetica-LightOblique') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica-Bold.eot');
  src: url('/fonts/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Helvetica-Bold.woff2') format('woff2'),
  url('/fonts/Helvetica-Bold.woff') format('woff'),
  url('/fonts/Helvetica-Bold.ttf') format('truetype'),
  url('/fonts/Helvetica-Bold.svg#/fonts/Helvetica-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica-Oblique.eot');
  src: url('/fonts/Helvetica-Oblique.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Helvetica-Oblique.woff2') format('woff2'),
  url('/fonts/Helvetica-Oblique.woff') format('woff'),
  url('/fonts/Helvetica-Oblique.ttf') format('truetype'),
  url('/fonts/Helvetica-Oblique.svg#/fonts/Helvetica-Oblique') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica-Light.eot');
  src: url('/fonts/Helvetica-Light.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Helvetica-Light.woff2') format('woff2'),
  url('/fonts/Helvetica-Light.woff') format('woff'),
  url('/fonts/Helvetica-Light.ttf') format('truetype'),
  url('/fonts/Helvetica-Light.svg#/fonts/Helvetica-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}
