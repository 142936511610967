:root {
  font-size: 16px;
  color: $color-text-primary;
  font-family: $font-primary;

  @include breakpoint($lg) {
    font-size: 15px;
  }
  @include breakpoint($md) {
    font-size: 14px;
  }
  @include breakpoint($sm) {
    font-size: 13px;
  }
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-underline {
  text-decoration: underline !important;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.w-full {
  width: 100vw;
}

.h-full {
  height: 100vh;
}

@for $i from 0 through 40 {
  .cpl-#{$i}, .cpx-#{$i}, .cp-#{$i} { padding-left: 0.125rem * $i !important; }
  .cpr-#{$i}, .cpx-#{$i}, .cp-#{$i} { padding-right: 0.125rem * $i !important; }
  .cpt-#{$i}, .cpy-#{$i}, .cp-#{$i} { padding-top: 0.125rem * $i !important; }
  .cpb-#{$i}, .cpy-#{$i}, .cp-#{$i} { padding-bottom: 0.125rem * $i !important; }

  .cml-#{$i}, .cmx-#{$i}, .cm-#{$i} { margin-left: 0.125rem * $i !important; }
  .cmr-#{$i}, .cmx-#{$i}, .cm-#{$i} { margin-right: 0.125rem * $i !important; }
  .cmt-#{$i}, .cmy-#{$i}, .cm-#{$i} { margin-top: 0.125rem * $i !important; }
  .cmb-#{$i}, .cmy-#{$i}, .cm-#{$i} { margin-bottom: 0.125rem * $i !important; }
}

.public-page {
  margin: 3rem auto;
  padding: 2rem 0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $color-white;
}

.error-border {
  border: 1px solid $color-light-red !important;
}

.text-vertical-limit {
  line-height: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-word;
}

.break-word {
  word-break: break-word;
}

.layout-container {
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 1300px;
  margin: auto;

  @include breakpoint($md) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

.ant-modal {
  top: 0 !important;
}