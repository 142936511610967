.phone-number-input {
  border: 1px solid $color-grey;
  border-radius: 4px;
  height: 2.75rem;

  &.error {
    border: 1px solid #f00;
    border-radius: 3px;
  }

  .default-input {
    width: 100%;
    height: 100%;
    padding: 0.25rem 72px;
    font-size: 0.875rem;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    color: $color-dark;
  }

  .flag-dropdown {
    width: 60px;
    border: none;
    background: $color-white;
    border-right: 1px solid #f0f0f0;

    .selected-flag {
      padding-left: 15px;
    }

    .flag {
      .arrow {
        left: 30px;
      }
    }
  }
}
