h1,
h2,
h3,
h4,
h5 {
  font-family: $font-primary;
  color: $color-dark;
}

a {
  color: $color-blue-main;
}

h1,
.text-heading1 {
  font-size: 2rem;
  line-height: 1.15625;
  font-weight: normal;
  letter-spacing: 0;
}

h2,
.text-heading2 {
  font-size: 1.625rem;
  line-height: 1.1538;
  font-weight: normal;
  letter-spacing: 0;
}

h3,
.text-heading3 {
  font-size: 1.375rem;
  line-height: 1.1363;
  font-weight: normal;
  letter-spacing: 0;
}

p,
span {
  font-family: $font-primary;
}

.text-body1 {
  font-size: 1rem;
  line-height: 1.4375;
  font-weight: normal;
  letter-spacing: 0;
}

.text-body2 {
  font-size: 0.875rem;
  line-height: 1.43;
  font-weight: normal;
  letter-spacing: 0;
}

.text-body3 {
  font-size: 0.75rem;
  line-height: 1.333;
  font-weight: normal;
  letter-spacing: 0;
}

.text-xxs {
  font-size: 0.625rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.43 !important;
}

.text-base,
.text-md {
  font-size: 1rem !important;
  line-height: 1.4375 !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-2xl {
  font-size: 1.375rem !important;
}

.text-3xl {
  font-size: 1.5rem !important;
}

.text-4xl {
  font-size: 1.625rem !important;
}

.text-5xl {
  font-size: 1.75rem !important;
}

.text-6xl {
  font-size: 1.875rem !important;
}

.text-7xl {
  font-size: 2rem !important;
}

.text-normal {
  font-weight: normal !important;
}

.text-bold {
  font-weight: bold !important;
}

.text-light {
  font-weight: 300 !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-italic {
  font-style: italic !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.text-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}

.text-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}

.text-clamp-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}
