.custom-ant-table {
  &.ant-table-wrapper {
    overflow: auto;

    .ant-table-cell {
      white-space: nowrap;
    }
  }

  .ant-table {
    color: $color-dark;
    background: $color-grey-light;

    table {
      border-radius: 4px;
      border: 1px solid $color-grey;
    }

    .ant-table-container, .ant-table-container table > thead > tr:first-child th:first-child {
      border-top-left-radius: 4px;
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
      border-top-right-radius: 4px;
    }

    .ant-table-container table > tbody > tr:last-child td:first-child {
      border-bottom-left-radius: 4px;
    }

    .ant-table-container table > tbody > tr:last-child td:last-child {
      border-bottom-right-radius: 4px;
    }

    .ant-table-thead > tr > th {
      border-color: $color-grey-light-2;
    }

    .ant-table-tbody > tr > td {
      border-bottom: none;
    }

    .ant-table-thead > tr > th {
      font-size: 1rem;
      font-weight: normal;
      color: $color-dark;
      background: $color-grey-light-3;
    }

    .ant-table-thead > tr > th:not(.ant-table-cell.ant-table-selection-column) {
      padding: 0.75rem 1.5rem 0.875rem;
    }

    .ant-table-tbody > tr > td:not(.ant-table-cell.ant-table-selection-column),
    .ant-table tfoot > tr > td:not(.ant-table-cell.ant-table-selection-column),
    .ant-table tfoot > tr > th:not(.ant-table-cell.ant-table-selection-column) {
      padding: 1rem 1.5rem 0.875rem;
      font-size: 0.875rem;
    }

    .ant-table-tbody > tr.ant-table-row-selected > td {
      background: $color-blue-ultra-light !important;
    }

    .ant-table-tbody {
      tr {
        &:nth-child(2n) {
          background: $color-grey-light-3;

          &:hover {
            td {
              background: $color-grey-light-3;
            }
          }

          .ant-table-cell-fix-left,
          .ant-table-cell-fix-right {
            background: $color-grey-light-3;
          }
        }

        &:nth-child(2n + 1) {
          background: $color-white;

          .ant-table-cell-fix-left,
          .ant-table-cell-fix-right {
            background: $color-white;
          }

          &:hover {
            td {
              background: $color-white;
            }
          }
        }
      }
    }

    th,
    td {
      &.ant-table-selection-column {
        padding-left: 20px;
        padding-right: 10px;
      }
    }
  }

  .ant-table-column-sorter {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  td.ant-table-column-sort {
    background: initial;
  }
}
