@mixin font-style($font-color, $font-family, $font-size, $weight: 400) {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $weight;
  color: $font-color;
}

@mixin flex-layout($direction: row) {
  display: flex;
  flex-direction: $direction;
}

@mixin flex-layout-align($js-value, $ai-value) {
  justify-content: $js-value;
  align-items: $ai-value;
}

@mixin breakpoint($max) {
  @media (max-width: $max) {
    @content;
  }
}

@mixin breakpoint-up($min) {
  @media (min-width: $min) {
    @content;
  }
}
