@import "src/styles/variables";
@import "src/styles/mixins";

.transaction-detail-drawer {
  .ant-drawer-header {
    border-bottom: none;
  }
  .ant-drawer-close {
    left: 0;
    right: auto;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }

  .ant-drawer-content-wrapper {
    width: 80vw !important;
    background: $color-grey-light;

    .ant-drawer-header, .ant-drawer-content {
      background: $color-grey-light;
    }

    @include breakpoint-up($md) {
      width: 600px !important;
    }
  }

  tr {
    td {
      padding: 0.5rem 1rem 0.5rem 0;
    }
  }

  .invoice-payment-table {
    margin-top: 3rem;
    border: 1px solid $color-grey;

    tr {
      border-bottom: 1px solid $color-grey;

      td {
        padding: 0.5rem 1rem;
      }
    }
  }
}
