@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/antd/dist/antd.min.css";
@import "../node_modules/react-perfect-scrollbar/dist/css/styles.css";
@import "../node_modules/react-phone-input-2/lib/style.css";
@import "../node_modules/react-date-range/dist/styles.css";
@import "../node_modules/react-date-range/dist/theme/default.css";
@import "styles";

html,
body {
  font-family: $font-primary !important;
  color: $color-dark !important;
  font-size: 1rem;
  background: $color-grey-light;
  width: 100% !important;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

::-webkit-scrollbar {
  width: 9px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbcbcb;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}
